import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Sidebar from "../components/Repeating/SidebarCta";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Encinitas Probate Litigation Lawyer",
      content: (
        <>
          <p>
            If a person passes away without a will, trust, or legal document
            outlining their wishes, the distribution of their assets may go to
            probate. It’s a process that ensures the assets are legally and
            properly transferred.
          </p>
          <p>
            It can be emotional for all involved, especially if the probate
            proceeding becomes contested. At that point, you’ll need qualified
            legal counsel to represent you and protect your interests, and
            that’s where our expert probate litigation attorneys in Encinitas
            come in. You’ll also want a legal representative on your side if:
          </p>
          <ul>
            <li>A trustee has breached their fiduciary duties</li>
            <li>The accountings or creditors’ claims are being contested</li>
            <li>
              There is a question of undue influence or mental capacity of the
              decedent
            </li>
          </ul>
          <a href="https://briertonjones.com/probate-litigation-lawyer/">
            Learn More About Probate Litigation
          </a>
        </>
      ),
    },
    {
      title: "Encinitas Trust Litigation Lawyer",
      content: (
        <>
          <p>
            A trust gives a third party the legal right to hold and manage
            assets after a person passes away. Litigation can come into play
            when a trust is contested, a trustee is not fulfilling their legal
            obligations, there’s a dispute over a conservatorship or
            guardianship, or there are claims of undue influence or even elder
            abuse.
          </p>
          <p>
            At Brierton, Jones & Jones, LLP, our lawyers have handled trust
            issues for decades and can help you through complex matters. You
            should contact us if:
          </p>
          <ul>
            <li>A trust is being contested</li>
            <li>A trustee is not fulfilling their legal obligations</li>
            <li>The accountings are being contested</li>
            <li>A creditor’s claim has been rejected</li>
            <li>There are disputes over a conservatorship or guardianship</li>
            <li>
              There are claims of undue influence, lack of capacity, or elder
              abuse
            </li>
          </ul>
          <a href="https://briertonjones.com/trust-estate-litigation-lawyer/">
            Learn More About Trust Litigation
          </a>
        </>
      ),
    },
    {
      title: "Encinitas Trust & Estate Mediation Lawyer",
      content: (
        <>
          <p>
            Sometimes, litigation is the only way to resolve a difficult trust
            and estate matter. However, mediation through a third party is an
            alternative that you may want to try first. A mediator can help find
            common ground, and litigation remains an option if no agreement can
            be reached in mediation.
          </p>
          <p>
            If you're trying to resolve a trust and estate dispute and want to
            consider mediation, our Encinitas lawyers can help guide you. It’s
            an option that is not only more cost-effective than litigation, but
            it’s more private and less adversarial.
          </p>
          <a href="https://briertonjones.com/trust-estate-mediation/">
            Learn More About Mediation
          </a>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Encinitas Litigation Lawyers | Brierton, Jones & Jones"
        description="Facing a legal dispute in Encinitas? Our skilled litigation lawyers offer effective solutions. Trust us to protect your interests. Call us for a consultation!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <article>
        <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
          <div className="relative">
            <div className="container">
              <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
                <div className="md:pr-6 lg:pr-12">
                  <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                    SECURE YOUR LEGACY
                  </div>
                  <h1>Encinitas Trust Litigation Lawyer</h1>
                  <p>
                    Struggling to resolve a complicated trust issue? The legal
                    team at Brierton, Jones & Jones, LLP is here to assist. With
                    over 30 years of experience in trust litigation, our trust
                    litigation lawyers remain committed to serving the Encinitas
                    community with competence, compassion, and respect. Let us
                    help you find a swift resolution for your trust-related
                    challenges.
                  </p>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Schedule a Consultation"
                  />
                </div>

                <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[45vw] md:pl-6 lg:pl-12">
                  <StaticImage
                    src="../images/city pages/Encinitas.png"
                    loading="eager"
                    className="h-full"
                    imgClassName="object-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-20 pt-14 md:mb-32 md:pt-20">
          <div className="container">
            <div className="flex justify-between md:space-x-14 lg:space-x-28">
              <div>
                <div className="mb-12 md:mb-18">
                  <h2>We Make Trust Disputes Disappear</h2>
                  <p>
                    Trust cases frequently involve significant assets and
                    complicated family dynamics, making disputes emotionally
                    charged, legally intricate, and needlessly unpleasant.
                  </p>
                  <p>
                    We know because we have over 30 years of experience as
                    Encinitas’ leading trust litigation team.
                  </p>

                  <p>
                    Lean on our team for support and reassurance. We protect our
                    clients' interests and work tirelessly towards a just
                    resolution.
                  </p>
                </div>
                <div>
                  <h3>How Can a Trust Litigation Attorney Help You?</h3>
                  <p>
                    We specialize in interpreting intricate trust documents,
                    resolving beneficiary conflicts, addressing breach of
                    fiduciary issues, and ensuring trusts are properly
                    administered. How can we help?
                  </p>
                </div>
                <div>
                  <h3>Contact Us When You Need a Resolution</h3>
                  <p>
                    Need help resolving disputes related to trust validation,
                    asset mismanagement, breach of fiduciary duty, or conflicts
                    over distribution? Call us today. Our legal team is here to
                    assist.
                  </p>
                </div>
                <div className="pt-[120px]">
                  <h3>Related Practice Areas</h3>
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                  >
                    {accordionContent.map((item, i) => (
                      <AccordionItem
                        uuid={i}
                        className="border-b border-gray-100 pb-5"
                      >
                        <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                    {item.title}
                                  </h3>
                                  <i
                                    className={`far ml-2 text-xl text-seafoam ${
                                      state.expanded
                                        ? "fa-minus"
                                        : "fa-chevron-down"
                                    }`}
                                  ></i>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          {item.content}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact us"
                    className="mt-8"
                  />
                </div>
              </div>

              <div className="hidden md:block">
                <div className="sticky top-24">
                  <aside>
                    <div className="ml-auto mt-14 w-[298px] rounded-3xl border border-seafoam px-4 py-6">
                      <div className="mb-4 font-display text-2xl font-semibold text-typography-heading">
                        Schedule a Consultation
                      </div>
                      <p className="font-display text-lg">
                        When you need an Encinitas trust litigation lawyer to
                        help you put everything in order, Brierton, Jones &
                        Jones, LLP is here for you.
                      </p>

                      <div className="space-y-4">
                        <ButtonGhost
                          href="tel:619-485-5394"
                          text="(619) 485-5394"
                          className="w-full"
                        />
                        <ButtonSolid
                          modal="modal-contact"
                          text="Send a Message"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mb-20">
            <StaticImage
              src="../images/city pages/Encinitas Map.jpg"
              loading="eager"
              className="w-full"
            />
          </div>
        </section>
      </article>
      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="When you need a San Diego estate planning attorney to help you put everything in order, Brierton, Jones & Jones, LLP is here for you." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
